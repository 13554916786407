import React from "react"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import HeaderFeaturedImage from "../components/common/HeaderFeaturedImage"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../components/common/Link"
import SEO from "../components/seo"

export default function ResearchDevelopmentPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 183 }) {
        content
        title

        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        activities {
          activity {
            nameActivity
            linkActivity {
              ... on WpPage {
                id
                uri
              }
            }
            descriptionActivity
            imageActivity {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.5)
                }
              }
            }
            logoActivity {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const content = data.wpPage.content
  const { title, featuredImage, disclaimer } = data.wpPage
  const img = data.wpPage.featuredImage?.node.localFile.childImageSharp
  const activities = data.wpPage.activities.activity

  return (
    <Layout>
      <SEO title={title} />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div className="max-w-6xl mx-auto py-8 lg:py-16 px-4 lg:px-8">
          <div className="flex flex-col gap-8 lg:gap-16">
            {!featuredImage ? <PageHeader text={title} /> : null}
            {content ? (
              <div className="max-w-3xl  mx-auto flex flex-col xl:flex-row gap-4 lg:gap-8 items-center">
                <div className="flex-1">
                  <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    className="wp-content"
                  />
                </div>
              </div>
            ) : null}
            <div
              id="activities"
              className="grid lg:grid-cols-3 gap-4 lg:gap-12"
            >
              {activities.map((activity, i) => {
                return <Activity key={i} activity={activity} />
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Activity({ activity }) {
  const { linkActivity, descriptionActivity, imageActivity, logoActivity } =
    activity
  const logo = logoActivity?.localFile.childImageSharp
  const img = imageActivity?.localFile.childImageSharp
  return (
    <div className="flex flex-col gap-4 items-center">
      {logo ? (
        <div className="w-1/3">
          {linkActivity ? (
            <Link to={linkActivity.uri}>
              <GatsbyImage image={getImage(logo)} />
            </Link>
          ) : (
            <GatsbyImage image={getImage(logo)} />
          )}
        </div>
      ) : null}
      {/* <div className="flex items-center gap-4">
        <h3 className="text-2xl font-bold">{nameActivity}</h3>
      </div> */}
      {img ? (
        <div className="flex flex-col items-center justify-center">
          <GatsbyImage
            image={getImage(img)}
            className="rounded-full w-32 lg:w-48 lg:h-48 h-32  opacity-[99%]"
          />
        </div>
      ) : null}

      <div>
        <div
          dangerouslySetInnerHTML={{ __html: descriptionActivity }}
          className="wp-content"
        />
      </div>
      {linkActivity ? (
        <div className="self-start">
          <div className="flex group flex-row gap-2 items-center text-secondary">
            <div className="relative group-hover:translate-x-3 transition-all cursor-pointer ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 144 145"
                style={{ minWidth: 18, width: 18, marginRight: "1rem" }}
              >
                <path d="M140.189 72.596a8.803 8.803 0 00-2.619-6.266L78.183 6.943c-3.446-3.446-9.088-3.447-12.527-.007-3.446 3.446-3.44 9.08 0 12.52L109.93 63.73l-90.764.002c-4.873 0-8.86 3.987-8.86 8.86.001 4.872 3.988 8.859 8.86 8.86l90.758.004-44.27 44.27c-3.445 3.446-3.439 9.08.001 12.52 3.446 3.446 9.081 3.44 12.52.001L137.57 78.85a8.772 8.772 0 002.619-6.254z"></path>
              </svg>
            </div>

            <div>
              <Link to={linkActivity?.uri}>Visit </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
